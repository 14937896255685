<template>
  <section v-if="currentUser">
    <TitleBar>Bonjour {{ prenomNom }}</TitleBar>
    <b-container fluid>
      <b-row class="pb-3 pt-md-3">
        <b-col class="mx-md-3">
          <h2>Mes coordonn&eacute;es</h2>
          <div>
            <EmailComponent
              :value="currentUser.email"
              :icon="true"
            ></EmailComponent>
          </div>
          <h2 class="mt-4">La s&eacute;curit&eacute; de mon compte</h2>
          <div class="mt-3">
            Votre session est valable jusqu'au
            <strong>{{ expirationSession }}</strong
            >, apr&egrave;s quoi vous devrez vous reconnecter.
            <br />
            <b-button pill variant="danger" @click.prevent="logout">
              D&eacute;connexion
            </b-button>
          </div>
          <div class="mt-3">
            Votre mot de passe est valable jusqu'au
            <strong>{{ expirationMotDePasse }}</strong
            >, apr&egrave;s quoi vous devrez en changer.
            <br />
            <b-button
              pill
              variant="outline-danger"
              @click.prevent="changePassword"
            >
              Modifier mon mot de passe
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <PasswordChangerDialog ref="passwordChangerDialog"></PasswordChangerDialog>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import EmailComponent from "../../components/EmailComponent.vue";
import PasswordChangerDialog from "../../components/dialogs/PasswordChangerDialog.vue";
export default {
  name: "AccountView",
  components: {
    TitleBar,
    EmailComponent,
    PasswordChangerDialog,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    expirationSession() {
      return new Date(this.currentUser.expirationSession).toLocaleString();
    },
    expirationMotDePasse() {
      return new Date(this.currentUser.expirationMotDePasse).toLocaleString();
    },
    prenomNom() {
      return this.$store.getters["auth/prenomNom"];
    },
  },
  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
    changePassword() {
      this.$refs.passwordChangerDialog.show();
    },
  },
};
</script>
